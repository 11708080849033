:root {
  --color-gradient: linear-gradient(to right, #fda9ff, #4dffe5);
  --color-background: #fff;
  --color-background-dark: #070927;
  --color-background--secondary: #f8f8f8;
  --color-border: #f0f0f0;
  --color-typography: #34353d;
  --color-typography-standard: var(--color-typography);
  --color-typography--light: #717292;
  --color-typography-dark: #fff;
  --color-typography-dark--light: #888aa2;
}

.dark-theme {
  --color-background: var(--color-background-dark);
  --color-background--secondary: #16183c;
  --color-border: #262947;
  --color-typography: var(--color-typography-dark);
  --color-typography--light: var(--color-typography-dark--light);
}
