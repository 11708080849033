@tailwind components;

@layer components {
  .gradient-border-image {
    border-image: var(--color-gradient) 1;
  }

  .gradient-border {
    background-image: linear-gradient(var(--color-background), var(--color-background)), var(--color-gradient);
    background-clip: content-box, border-box;
    @apply border-solid border-2 border-transparent bg-origin-border;
  }
}
