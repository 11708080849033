@tailwind components;

@layer components {
  button.primary {
    @apply py-3 px-4 rounded-full text-xl text-typography-standard flex justify-center items-center;
  }

  button.primary:not(:disabled) {
    background-size: 100% 50%;
    transition: background-position .5s, background-size .5s;
    background-image: var(--color-gradient);
  }

  button.primary:disabled {
    @apply bg-border text-typography-light;
  }

  button.primary:hover:not(:disabled) {
    background-size: 200% 50%;
    background-position: 100% 50%;
  }
}
