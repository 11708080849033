@import "./colors.css";
@import "./gradient.css";
@import "./button.css";
@import "./typography.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--color-typography);
}

.dark-theme {
  color: var(--color-typography);
}

@font-face {
  font-family: Moret;
  src: url(https://astrolabe.umee.cc/assets/Moret-Regular.88094759.otf)
}
